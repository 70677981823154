import React, { useEffect } from "react";

import {
  List,
  Grid,
  Tooltip,
  IconButton,
  Avatar,
  Stack,
  ListItem,
  Collapse,
  Box,
  Typography,
  Container,
  Fab,
  Skeleton,
} from "@mui/material";

import StepCard from "./stepCard";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import StepForm from "./stepForm";
import { builder_step } from "./types";

export default function StepList(props: {
  stepArray: builder_step[];
  setStepArray: React.Dispatch<React.SetStateAction<builder_step[]>>;
  updateStep: any;
  updateTrigger: boolean;
}) {
  const [stepArray, setStepArray] = React.useState<builder_step[]>(
    props.stepArray
  );

  const [selectedStepIndex, setSelectedStepIndex] = React.useState<
    number | null
  >(null);

  useEffect(() => {
    setStepArray(props.stepArray);
    if (typeof selectedStepIndex === "number") {
      setSelectedStep(props.stepArray[selectedStepIndex]);
    }
  }, [props.stepArray, selectedStepIndex]);

  const [selectedStep, setSelectedStep] = React.useState<builder_step>(
    {} as builder_step
  );

  const updateCurrentChangeSelectedStep = (newIndex: number | null) => {
    let step = selectedStep;
    delete step.fadeIn;
    props.updateStep(selectedStepIndex, step);
    setSelectedStepIndex(newIndex);
  };
  useEffect(() => {
    updateCurrentChangeSelectedStep(null);
  }, [props.updateTrigger]);

  const [isPasteEnabled, setIsPasteEnabled] = React.useState<boolean>(false);
  useEffect(() => {
    /* set a listener for when the page focus changes */
    var handler = function (event: any) {
      if (document.hasFocus()) {
        CheckClipboard(setIsPasteEnabled);
      }
    };
    document.addEventListener("focus", handler, true);

    /* also check the clip status on first load */
    CheckClipboard(setIsPasteEnabled);
  }, []);

  /* paste in the config info from the step builder page in the extension */
  const pasteConfig = () => {
    navigator.clipboard.readText().then((clipText) => {
      try {
        const configInfo = JSON.parse(clipText);

        props.updateStep(selectedStepIndex, configInfo as builder_step);
      } catch {
        alert("Unable to paste step information");
      }
    });
  };

  const addStep = (index: number) => {
    let tempStepArray = [...stepArray];

    /* set fadeIn & fadeOut to false for all existing steps and swap out the step to be removed with */
    tempStepArray.forEach((step: builder_step) => {
      delete step.fadeIn;
      delete step.fadeOut;
    });

    /* insert a step at the specified position */
    let newStep = {
      fadeIn: true,
    } as builder_step;
    tempStepArray.splice(index + 1, 0, newStep);

    setSelectedStep(newStep);
    setSelectedStepIndex(index + 1);
    props.setStepArray(tempStepArray);
  };

  const removeStep = (index: number) => {
    let tempStepArray = [...stepArray];

    /* set fadeIn & fadeOut to false for all existing steps and swap out the step to be removed with */
    tempStepArray.forEach((step: builder_step) => {
      delete step.fadeIn;
      delete step.fadeOut;
    });

    tempStepArray[index].fadeOut = true;
    setSelectedStepIndex(null);
    props.setStepArray(tempStepArray);

    setTimeout(() => {
      const newTempStepArray = [...tempStepArray];
      newTempStepArray.splice(index, 1);

      props.setStepArray(newTempStepArray);
    }, 500);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        flexGrow: 1,
        bgcolor: "white",
        width: "100%",
        height: "900px",
      }}
    >
      <Stack direction="row" sx={{ display: "flex", height: "100%", py: 2 }}>
        <Box width={"48%"}>
          <Typography variant="h4" color="secondary.dark">
            Step List
          </Typography>

          {props.stepArray && props.stepArray.length > 0 ? (
            <List sx={{ height: "96%", mb: 2, overflow: "scroll" }}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Tooltip title={"Add a step"} placement="right">
                    <IconButton
                      aria-label="add a step"
                      onClick={() => {
                        addStep(-1);
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {stepArray.map((value, index) => {
                const labelId = `checkbox-list-label-${
                  JSON.stringify(value) + index.toString()
                }`;

                return (
                  <SmoothAppear
                    fadeIn={value.fadeIn}
                    fadeOut={value.fadeOut}
                    key={labelId}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={11}>
                        <Stack width={"100%"}>
                          <ListItem key={labelId}>
                            <StepCard
                              index={index}
                              step={
                                index === selectedStepIndex
                                  ? selectedStep
                                  : stepArray[index]
                              }
                              setSelectedStepIndex={
                                updateCurrentChangeSelectedStep
                              }
                              isSelected={index === selectedStepIndex}
                              setSelectedStep={setSelectedStep}
                            ></StepCard>
                          </ListItem>
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title={"Remove step"} placement="bottom">
                          <IconButton
                            sx={{ p: 0 }}
                            aria-label="remove step"
                            onClick={() => {
                              removeStep(index);
                            }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Tooltip title={"Add a step"} placement="right">
                      <IconButton
                        aria-label="add a step"
                        onClick={() => {
                          addStep(index);
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </SmoothAppear>
                );
              })}
            </List>
          ) : (
            [...Array(4)].map((c, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                width={"100%"}
                height={150}
                sx={{ m: 2 }}
              />
            ))
          )}
        </Box>
        <Box width={"4%"}></Box>
        <Box width={"48%"}>
          <Stack
            direction="row"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="primary.dark"
          >
            <Typography variant="h4" color="secondary.dark" mr="3px">
              Step
            </Typography>
            {selectedStepIndex !== null && (
              <Avatar
                variant="rounded"
                sx={{
                  backgroundColor: "secondary.dark",
                  my: "3px",
                }}
              >
                {selectedStepIndex + 1}
              </Avatar>
            )}
            <Typography variant="h4" color="secondary.dark" ml="3px">
              Details
            </Typography>
          </Stack>
          {props.stepArray && props.stepArray.length > 0 ? (
            <Box sx={{ height: "95%", mb: 2, overflow: "scroll" }}>
              <StepForm
                index={selectedStepIndex}
                step={selectedStep}
                setSelectedStep={setSelectedStep}
              />
            </Box>
          ) : (
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={"93%"}
              sx={{ m: 2 }}
            />
          )}
        </Box>
      </Stack>
      <Fab
        disabled={!isPasteEnabled}
        variant="extended"
        sx={{
          position: "fixed",
          pl: 10,
          pr: 10,
          bottom: "30px",
          right: "280px",
        }}
        color="secondary"
        onClick={pasteConfig}
      >
        Paste from Clipboard
      </Fab>
    </Container>
  );
}

function SmoothAppear({ fadeIn , fadeOut, children }: { fadeIn: any, fadeOut: any, children: any}) {
  const [showStep, setShowStep] = React.useState<boolean>(true);

  if (fadeOut) {
    setTimeout(() => {
      setShowStep(false);
    }, 10);
  }

  return (
    <Collapse in={showStep} appear={fadeIn}>
      {children}
    </Collapse>
  );
}

function CheckClipboard(setIsClipStep: any) {
  /* check if the contents of the clipboard conform to the 'step' data formant and set the state accordingly */
  try {
    /* throw an error if the page is not in focus */
    if (!document.hasFocus()) {
      throw Error;
    }

    navigator.clipboard.readText().then((clipText) => {
      /* also throw an error if the contents of the clipboard are not valid JSON */
      try {
        JSON.parse(clipText);

        const subjectArr = Object.keys(JSON.parse(clipText));
        const testArr = ["anchorElement", "title", "description", "dialogBox"];

        let checker = (arr: string[], target: string[]) =>
          target.every((v: string) => arr.includes(v));

        setIsClipStep(checker(subjectArr, testArr));
      } catch {
        setIsClipStep(false);
      }
    });
  } catch {
    setIsClipStep(false);
  }
}
