import { createTheme, alpha } from "@mui/material/styles";
import { Button, InputAdornment, List, TextField } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

/* note on fonts: 
for content within the react app, there are 2 components required - the google fonts links in index.html and fontFamily definition below
fonts in the injected dialog, however, are a different beast, and require font definitions in the content script that reference local files */

export function DynamicTheme(color: string) {
  return createTheme({
    palette: {
      primary: {
        main: "#FB8C37",
        light: "#F3F5F7",
        dark: "#36454F",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: color ? color : "#FB8C37",
        light: alpha(color ? color : "#FB8C37", 0.3),
      },
      divider: "#ADADAD",
      info: { main: "#ADADAD" },
    },
    typography: {
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
    },
  });
}

export const client = {
  Autocomplete_SlotProps: {
    paper: {
      sx: {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option[aria-selected='true']": {
            bgcolor: "secondary.light",
            "&.Mui-focused": {
              bgcolor: "secondary.main",
              color: "white",
            },
          },
        },
      },
    },
  },
  Select_MenuProps: {
    PaperProps: {
      sx: {
        "& .MuiMenuItem-root.Mui-selected": {
          backgroundColor: "secondary.light",
        },
        "& .MuiMenuItem-root.Mui-selected:hover": {
          backgroundColor: "secondary.light",
          fontWeight: "bold",
        },
      },
    },
  },
};

export const NextStepList = (props: any) => (
  <List
    sx={{
      "&& .Mui-selected": {
        bgcolor: "secondary.light",
      },
      "&& .Mui-selected.Mui-selected:hover": {
        bgcolor: "secondary.main",
      },
      overflow: "auto",
    }}
  >
    {props.children}
  </List>
);

export const ModalList = (props: any) => (
  <NextStepList
    sx={{
      width: "100%",
      bgcolor: "background.paper",
      position: "relative",
      overflow: "auto",
      maxHeight: 300,
      "& ul": { padding: 0 },
    }}
    subheader={<li />}
  >
    {props.children}
  </NextStepList>
);

export const SearchField = (props: any) => (
  <TextField
    {...props}
    color="secondary"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
      sx: { borderRadius: 50 },
    }}
  />
);

export const NextStepButton = (props: any) => (
  <Button
    {...props}
    sx={{
      m: 2,
      backgroundColor: "primary.dark",
      "&:hover": {
        backgroundColor: "primary.main",
      },
    }}
  />
);
