import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";
import Skeleton from "@mui/material/Skeleton";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { sequenceGroup } from "./types";

const rowHeaders = ["Process group", "# of process", "Edit"];

export default function CollectionCard({ collection, seqGroups, setEditModalOpen,setAddModalOpen, setSequenceGroup}: {
  collection: any;
  seqGroups: sequenceGroup;
  setEditModalOpen: any;
  setAddModalOpen: any;
  setSequenceGroup: React.Dispatch<React.SetStateAction<sequenceGroup>>;
}) {
  const cards = collection
    ? [
        {
          key: "Name",
          value: collection.display_name || collection.id,
        },
        { key: "Status", value: collection.status || "active" },
        {
          key: "Date Created",
          value: collection.date_created
            ? new Date(
                collection.date_created._seconds * 1000
              ).toLocaleDateString("en-US")
            : "unknown",
        },
      ]
    : [];

  /* read in and set sequence groups */
  const [collectionSeqGroups, setCollectionSeqGroups] = React.useState<any>([]);

  React.useEffect(() => {
    if (collection) {
      const flatCollectionSeqGroupArr = (seqGroups as any).map(
        (a: any) => Object.values(a)[0]
      );

      setCollectionSeqGroups(flatCollectionSeqGroupArr);
    }
  }, [seqGroups, collection]);

  return (
    <React.Fragment>
      <Paper
        elevation={4}
        sx={{
          backgroundColor: "secondary.dark",
          p: 2,
          height: "450px",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6" pb={2} align="left" color={"white"}>
          Collection Info
        </Typography>
        {!collection && (
          <Typography
            fontStyle="italic"
            color="divider"
            variant="h5"
            pb={2}
            pt={10}
          >
            Select a Collection to view details here
          </Typography>
        )}
        {collection && (
          <React.Fragment>
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={5} key={collection.id + " overview"}>
                  {cards.map((card: any) => (
                    <Card
                      key={card.key}
                      sx={{
                        mb: 2,
                        minWidth: 175,
                        height: 89,
                        backgroundColor: "primary.light",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold" }}
                          color="secondary.main"
                          gutterBottom
                        >
                          {card.key}
                        </Typography>
                        {collection ? (
                          <Typography
                            variant="h6"
                            component="div"
                            color="primary.dark"
                          >
                            {card.value}
                          </Typography>
                        ) : (
                          <Skeleton variant="rounded" height={70} />
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </Grid>
                <Grid item xs={7} key={collection.id}>
                  <Card
                    sx={{
                      overflow: "scroll",
                      mb: 2,
                    }}
                  >
                    <CardContent sx={{ height: "300px" }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            {rowHeaders.map((rowHeader: string) => (
                              <TableCell
                                key={rowHeader}
                                align={rowHeader === "Edit" ? "center" : "left"}
                                sx={{ color: "secondary.main" }}
                              >
                                <b>{rowHeader}</b>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {collectionSeqGroups &&
                          collectionSeqGroups.length > 0 && (
                            <TableBody>
                              {collectionSeqGroups.map((row: any) => (
                                <TableRow key={row.id}>
                                  <TableCell width={"60%"}>
                                    {row.displayName}
                                  </TableCell>
                                  <TableCell width={"30%"}>
                                    {row.sequenceList.length || 0}
                                  </TableCell>
                                  <TableCell align="right" width={"10%"}>
                                    <Tooltip
                                      title={"Edit Process Group"}
                                      placement="left"
                                    >
                                      <IconButton
                                        aria-label="editSequenceGroup"
                                        color="secondary"
                                        onClick={() => {
                                          setEditModalOpen(true);
                                          setSequenceGroup(row);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          )}
                        {!collectionSeqGroups && (
                          <TableBody>
                            <TableRow>
                              {/* column for each header */}
                              {rowHeaders.map((c, ic) => (
                                <TableCell key={c + ic}>
                                  {/* n = 10 rows */}
                                  {[...Array(10)].map((r, ir) => (
                                    <Skeleton
                                      key={"column:" + ic + "row:" + ir}
                                      variant="text"
                                      sx={{ width: "100%", fontSize: "1rem" }}
                                    />
                                  ))}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </CardContent>
                  </Card>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                    onClick={() => {
                      setAddModalOpen(true);
                    }}
                  >
                    Add Process Group
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
}
