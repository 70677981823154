import React from "react";

import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  Avatar,
} from "@mui/material";
import { builder_step, step } from "./types";

export default function StepCard(props: {
  index: number;
  step: step;
  setSelectedStepIndex: any;
  isSelected: boolean;
  setSelectedStep: React.Dispatch<React.SetStateAction<builder_step>>;
}) {
  const handleInputChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = e.target.id;

    /* need to update both the local formStep (for rendering) and the inherited stepArray (for changes from above) */
    const tempFormStep = JSON.parse(JSON.stringify(props.step));
    tempFormStep[property] = e.target.value;
    props.setSelectedStep(tempFormStep);
  };

  return (
    <Card
      elevation={4}
      sx={{
        m: 0.5,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        bgcolor: "primary.light",

        boxShadow: (theme) =>
          `0em 0px 0.8em rgb(0 0 0 / 30%), 6px 6px ${
            props.isSelected
              ? theme.palette.secondary.main
              : theme.palette.divider
          }`,
      }}
    >
      <Avatar
        variant="rounded"
        sx={{
          margin: "auto",
          backgroundColor: props.isSelected ? "secondary.dark" : "divider",
          position: "absolute",
          top: "0px",
          left: "5px",
        }}
      >
        {props.index + 1}
      </Avatar>
      <CardActionArea>
        <CardContent
          sx={{
            pl: 4,
            borderColor: "secondary",
            borderWidth: props.isSelected ? 4 : 0,
          }}
          onClick={() => {
            if (!props.isSelected) {
              props.setSelectedStepIndex(props.index);
              props.setSelectedStep(props.step);
            }
          }}
        >
          {props.isSelected ? (
            <React.Fragment>
              <TextField
                id="title"
                variant="standard"
                fullWidth
                multiline
                hiddenLabel
                value={props.step.title || `Step ${props.index + 1} title`}
                color="secondary"
                type="search"
                margin="dense"
                InputProps={{
                  style: {
                    fontSize: 24,
                    fontWeight: "bold",
                    fontStyle: props.step.title ? "normal" : "italic",
                    backgroundColor: "#ffffff",
                  },
                  disableUnderline: true,
                }}
                onChange={handleInputChange}
              />
              <TextField
                id="subtitle"
                variant="standard"
                fullWidth
                multiline
                hiddenLabel
                value={
                  props.step.subtitle || `Step ${props.index + 1} subtitle`
                }
                color="secondary"
                type="search"
                margin="dense"
                size="small"
                InputProps={{
                  style: {
                    fontSize: 20,
                    color: "#999999",
                    fontStyle: props.step.subtitle ? "normal" : "italic",
                    backgroundColor: "#ffffff",
                  },
                  disableUnderline: true,
                }}
                onChange={handleInputChange}
              />
              <TextField
                id="description"
                variant="standard"
                fullWidth
                multiline
                hiddenLabel
                value={
                  props.step.description ||
                  `Step ${props.index + 1} description`
                }
                color="secondary"
                type="search"
                margin="dense"
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontStyle: props.step.description ? "normal" : "italic",
                    backgroundColor: "#ffffff",
                  },
                  disableUnderline: true,
                }}
                onChange={handleInputChange}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography
                fontSize={"24px"}
                fontWeight={"bold"}
                color="primary.dark"
                fontStyle={props.step.title ? "normal" : "italic"}
              >
                {props.step.title || `Step ${props.index + 1} title`}
              </Typography>
              <Typography
                fontSize={"20px"}
                color="#999999"
                fontStyle={props.step.title ? "normal" : "italic"}
              >
                {props.step.subtitle || `Step ${props.index + 1} subtitle`}
              </Typography>
              <br />
              <Typography
                variant="body2"
                color="primary.dark"
                fontStyle={props.step.title ? "normal" : "italic"}
              >
                {props.step.description ||
                  `Step ${props.index + 1} description`}
              </Typography>
            </React.Fragment>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}