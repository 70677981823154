import { Paper, Grid, Typography, Card, Divider } from "@mui/material";
import React, { useEffect } from "react";
import ReactCardFlip from "react-card-flip";

const impactCards: any = [
  {
    title: "New Users",
    description: "Learn processes by performing them in the your systems",
    challenge:
      "Training new users via traditional methods is time consuming and expensive",
  },
  {
    title: "New Tools",
    description:
      "Effectively and efficiently distribute technology transformations",
    challenge: "New tool implementations disrupt day-to-day operations",
  },
  {
    title: "Reference Guides",
    description:
      "Readily available access to complex, low-use and key processes",
    challenge: "Seldom-used processes are easily misremembered or forgotten",
  },
];

export default function ImpactCards(props: { isMobile: boolean }) {
  const [flippedCard, setFlippedCard] = React.useState<string | null>(null);
  const [userInteracted, setUserInteracted] = React.useState<boolean>(false);

  useEffect(() => {
    /* change tabs every 5s until the user interacts with the page */
    const interval = setInterval(() => {
      if (!userInteracted) {
        if (flippedCard === null) {
          setFlippedCard(impactCards[0].title);
        } else if (flippedCard === impactCards[0].title) {
          setFlippedCard(impactCards[1].title);
        } else if (flippedCard === impactCards[1].title) {
          setFlippedCard(impactCards[2].title);
        } else if (flippedCard === impactCards[2].title) {
          setFlippedCard(null);
        }
      }
    }, 3500);

    /* cleanup */
    return () => clearInterval(interval);
  }, [flippedCard, userInteracted]);

  return (
    <Paper
      elevation={0}
      sx={{
        py: 6,
        px: 10,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Grid container spacing={3} direction={{ xs: "column", md: "row" }}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h4" fontWeight={"bold"}>
            Impact
          </Typography>
          <br />
          <Typography color="primary.dark" variant="h6">
            NextStep provides a unique training experience that enables users to
            <Typography
              px={1}
              component="span"
              variant="inherit"
              color="primary"
            >
              learn by doing.
            </Typography>
            We make knowledge highly accessible through a step-by-step
            interactive process directly for your web-based applications.
          </Typography>
          <br />
        </Grid>
        <Grid item xs={1.5}></Grid>

        {impactCards.map((card: any) => {
          return (
            <Grid
              onMouseOver={() => {
                setFlippedCard(card.title);
                setUserInteracted(true);
              }}
              onMouseOut={() => {
                setFlippedCard(null);
              }}
              key={card.title}
              item
              xs={3}
              id={card.title}
            >
              <ReactCardFlip
                containerStyle={{ height: "100%" }}
                isFlipped={flippedCard === card.title}
                flipDirection={props.isMobile ? "vertical" : "horizontal"}
              >
                <ImpactCard
                  type="solution"
                  title={card.title}
                  description={card.description}
                />
                <ImpactCard
                  type="problem"
                  title={card.title}
                  description={card.challenge}
                />
              </ReactCardFlip>
            </Grid>
          );
        })}
        <Grid item xs={1.5}></Grid>
      </Grid>
    </Paper>
  );
}

function ImpactCard(props: {
  type: string;
  title: string;
  description: string;
}) {
  return (
    <Card
      elevation={4}
      sx={{
        bgcolor: props.type === "problem" ? "primary.light" : "primary.dark",
        p: 3,
        display: "flex",
        flexDirection: "column",
        maxHeight: 260,
        width: "100%",
        height: "100%",
      }}
    >
      <Typography
        color={props.type === "problem" ? "primary.dark" : "primary.main"}
        fontWeight="bold"
        variant="h5"
      >
        {props.title}
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography
        color={props.type === "problem" ? "primary.dark" : "white"}
        variant="body1"
      >
        {props.description}
      </Typography>
    </Card>
  );
}
