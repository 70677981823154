import React, { useEffect } from "react";

import {
  Autocomplete,
  Fab,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import StepList from "./stepList";
import SequenceModal from "../modals/sequenceModal";
import { client } from "../../../Config/styling";
import { sequence, step } from "./types";

export default function Sequence(props: {
  action: string;
  token: string;
  stepArray: step[];
  sequenceInfo: sequence;
  seqGroupDocId: string;
  groupSequences: sequence[];
}) {
  const [seqModalOpen, setSeqModalOpen] = React.useState<boolean>(false);

  const [stepArray, setStepArray] = React.useState<step[]>(props.stepArray);

  const updateStep = (index: number, step: step) => {
    const newStepArray = JSON.parse(JSON.stringify(stepArray));
    newStepArray[index] = { ...newStepArray[index], ...step };
    setStepArray(newStepArray);
  };

  const [sequenceInfo, setSequenceInfo] = React.useState<any>(
    props.sequenceInfo
  );

  const [updateTrigger, setUpdateTrigger] = React.useState<boolean>(false);
  const [submitReady, setSubmitReady] = React.useState<boolean>(false);
  useEffect(() => {
    setSubmitReady(false);
  }, [stepArray]);

  useEffect(() => {
    setStepArray(props.stepArray);
    // We change it to use the old data from sequence, this is the right way to update a value in additial
    setSequenceInfo((prev: any) => ({ ...prev, ...props.sequenceInfo }));
  }, [props.sequenceInfo, props.stepArray, setStepArray, setSequenceInfo]);

  /* when seqGroupDocId is not set is updated, need to wipe seq and stepArray */
  useEffect(() => {
    if (!props.seqGroupDocId) {
      setStepArray([]);
    }
  }, [props.seqGroupDocId]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    status: string
  ) => {
    setSequenceInfo({
      ...sequenceInfo,
      status,
    });
  };

  const handleInputChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = e.target.id;

    const tempSequenceInfo = JSON.parse(JSON.stringify(sequenceInfo));

    tempSequenceInfo[property] = e.target.value;
    setSequenceInfo(tempSequenceInfo);
  };

  const handleChainChange = (e: any, value: any) => {
    setSequenceInfo({ ...sequenceInfo, chainedSequences: value });
  };

  const [seqInfo, setSeqInfo] = React.useState<any>({});

  const handleSubmit = () => {
    let seqObj = JSON.parse(JSON.stringify(sequenceInfo));
    seqObj.stepList = stepArray;

    const seqInfo = {
      seqGroup: props.seqGroupDocId,
      seqId: props.sequenceInfo.id,
      seqObj: seqObj,
    };
    setSeqInfo(seqInfo);
    setSeqModalOpen(true);
  };

  return (
    <Stack sx={{ display: "flex" }}>
      <Grid container alignItems="top" p={1} spacing={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            color="secondary"
            key="description"
            id="description"
            label="Description"
            value={sequenceInfo.description || ""}
            type="search"
            variant="standard"
            style={{
              padding: 10,
            }}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            color="secondary"
            key="initialUrl"
            id="initialUrl"
            label="Initial Url"
            value={sequenceInfo.initialUrl || ""}
            type="search"
            variant="standard"
            style={{
              padding: 10,
            }}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={4} alignContent={"center"}>
          <ToggleButtonGroup
            color="secondary"
            value={sequenceInfo.status || "active"}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="disabled">Disabled</ToggleButton>
            <ToggleButton value="draft">Draft</ToggleButton>
            <ToggleButton value="active">Active</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <StepList
        stepArray={stepArray}
        setStepArray={setStepArray}
        updateStep={updateStep}
        updateTrigger={updateTrigger}
      />

      <Autocomplete
        sx={{ p: 2 }}
        multiple
        limitTags={5}
        id="chained-processes"
        slotProps={client.Autocomplete_SlotProps}
        options={(props.groupSequences || []).filter(
          (seq: any) => seq.id !== sequenceInfo.id
        )}
        value={sequenceInfo.chainedSequences || []}
        onChange={handleChainChange}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id || false
        }
        getOptionLabel={(option: any) => option.name}
        getOptionKey={(option: any) => option.id}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="standard"
            label="Chained Processes"
            placeholder="Available Options"
          />
        )}
      />

      {seqModalOpen && (
        <SequenceModal
          token={props.token}
          open={seqModalOpen}
          setOpen={setSeqModalOpen}
          action={props.action}
          seqInfo={seqInfo}
        />
      )}

      <Fab
        variant="extended"
        sx={{
          position: "fixed",
          pl: 10,
          pr: 10,
          bottom: "30px",
          right: "30px",
        }}
        color="secondary"
        onClick={
          !submitReady
            ? (e) => {
                setUpdateTrigger(!updateTrigger);
                setTimeout(() => {
                  setSubmitReady(true);
                }, 1);
              }
            : (e) => {
                e.preventDefault();
                handleSubmit();
              }
        }
      >
        {!submitReady ? "Save" : "Submit"}
      </Fab>
    </Stack>
  );
}