import React, { useContext, useEffect } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import { ModalStyle2 } from "../../../Components/modalStyle";

import { GetOrgData, AddEditSequence } from "../../../firebase/getUserData";
import DashboardContext from "../../../Contexts/dashboardContext";
import UserContext from "../../../Contexts/userContext";
import { ModalList } from "../../../Config/styling";

export default function SequenceModal(props: {
  token: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: string;
  seqInfo: any;
}) {
  const { setOrgSeqGroups } = useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  const [message, setMessage] = React.useState<any>("");

  const handleSubmit = () => {
    setMessage(<Loading />);

    AddEditSequence(
      logout,
      props.action,
      props.token,
      props.seqInfo.seqGroup,
      props.seqInfo.seqObj,
      props.seqInfo.seqId
    ).then((response: any) => {
      /* happy path */
      if (response.result === "success") {
        setMessage(
          <SubmitSuccess setOpen={props.setOpen} message={response.message} />
        );

        /* refresh orgSeqList */
        GetOrgData(props.token, ["sequenceList"], logout).then(
          (result: any) => {
            setTimeout(() => {
              setOrgSeqGroups(result.sequenceGroups);
              props.setOpen(false);
            }, 5000);
          }
        );

        /* unhappy path */
      } else {
        setMessage(
          <SubmitError setOpen={props.setOpen} message={response.message} />
        );
      }
    });
  };

  const validateSequence = () => {
    let tempErrors: any[] = [];

    /* sequence group errors */
    if (!props.seqInfo.seqGroup) {
      tempErrors.push({
        category: "Process Group",
        items: ["Select a process group"],
      });
    }

    /* sequence errors */
    let sequenceErrors: string[] = [];
    if (props.action === "add" && !props.seqInfo.seqObj.name) {
      sequenceErrors.push("Process name cannot be blank");
    }
    // } else if (props.action === "edit" && !props.seqInfo.seqId) {
    //   sequenceErrors.push("Select a process to edit");
    // }
    if (!props.seqInfo.seqObj.description) {
      sequenceErrors.push("Process Description cannot be blank");
    }
    if (!props.seqInfo.seqObj.initialUrl) {
      sequenceErrors.push("Initial URL cannot be blank");
    }
    if (sequenceErrors.length > 0) {
      tempErrors.push({
        category: "Process Info",
        items: sequenceErrors,
      });
    }
    if (
      !props.seqInfo.seqObj.stepList ||
      props.seqInfo.seqObj.stepList.length === 0
    ) {
      tempErrors.push({
        category: "No steps",
        items: ["Add at least one step to continue"],
      });
    } else {
      props.seqInfo.seqObj.stepList.forEach((step: any, index: number) => {
        let errorItems: any[] = [];
        /* need to handle both versions of the selector (old and new) */
        if (step.anchorElement) {
          if (!step.anchorElement.selector)
            errorItems.push("Selector cannot be blank");
        } else {
          if (!step.selector) errorItems.push("Selector cannot be blank");
        }
        if (!step.title) errorItems.push("Title cannot be blank");
        if (!step.description) errorItems.push("Description cannot be blank");

        if (errorItems.length > 0) {
          tempErrors.push({
            category: "Step " + (index + 1),
            items: errorItems,
          });
        }
      });
    }

    if (tempErrors.length > 0) {
      return { valid: false, errors: tempErrors };
    } else {
      return { valid: true };
    }
  };
  const [sequenceTestResults] = React.useState<any>(validateSequence());

  // TODO: We need to rework this whole component.
  // We could find a better way of displaying the sequene,
  useEffect(() => {
    // (async () => {
    // const sequenceTest = await validateSequence();

    if (sequenceTestResults.valid) {
      setMessage(
        <ValidSequence
          action={props.action}
          setOpen={props.setOpen}
          handleSubmit={handleSubmit}
        />
      );
    } else {
      setMessage(
        <InvalidSequence
          setOpen={props.setOpen}
          errors={sequenceTestResults.errors}
        />
      );
    }
    // })();
  }, [sequenceTestResults, props.action, props.setOpen]);

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>{message && <Box>{message}</Box>}</Box>
    </Modal>
  );
}

function ValidSequence({
  action,
  setOpen,
  handleSubmit,
}: {
  action: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: any;
}) {
  return (
    <Box>
      <Alert severity={"info"}>Are you sure?</Alert>
      {action === "edit" && (
        <Typography variant="h6" p={2}>
          Please confirm your changes; existing values will be permanently
          overwritten.
        </Typography>
      )}
      {action === "add" && (
        <Typography variant="h6" p={2}>
          No errors detected - click 'Submit' to add this process.
        </Typography>
      )}
      <Stack direction="row" sx={{ bottom: 0 }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          sx={{ m: 1 }}
        >
          SUBMIT
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          sx={{ m: 1 }}
          onClick={() => {
            setOpen(false);
          }}
        >
          CANCEl
        </Button>
      </Stack>
    </Box>
  );
}

function InvalidSequence({
  setOpen,
  errors,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  errors: any[] | undefined;
}) {
  return (
    <Box>
      <Alert severity="warning">Warning: errors detected</Alert>
      <Typography variant="h6" p={2}>
        You must correct the following errors to continue
      </Typography>
      <ModalList>
        {errors?.map((error: any) => (
          <li key={`section-${error.category}`}>
            <ul>
              <ListSubheader sx={{ bgcolor: "primary.light" }}>
                {error.category}
              </ListSubheader>
              {error.items.map((item: any) => (
                <ListItem key={`item-${error.category}-${item}`}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </ul>
          </li>
        ))}
      </ModalList>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}

function Loading() {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"info"}>Loading...</Alert>
      <br />
      <br />
      <LinearProgress color="secondary" />
      <br />
      <br />
    </Box>
  );
}

function SubmitSuccess({
  setOpen,
  message,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"success"}>{"SUCCESS"}</Alert>
      <Typography id="modal-modal-description" variant="h6" sx={{ mt: 2 }}>
        {message}
      </Typography>
      <br />
      <Button
        color="secondary"
        variant="outlined"
        fullWidth
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}

function SubmitError({
  setOpen,
  message,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"error"}>{"ERROR"}</Alert>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {message}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        sx={{ m: 1 }}
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}
