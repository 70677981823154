import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Card, Stack, Typography } from "@mui/material";

import OverviewTabs from "./overviewTabs";
import FeatureStepper from "./featureStepper";

import Footer from "../../Components/footer";

import { config } from "../../Config/config";
import ImpactCards from "./impactCards";
import { useNavigate } from "react-router-dom";
import { NextStepButton } from "src/Config/styling";

export default function HomePage(props: { isMobile: boolean }) {
  let navigate = useNavigate();

  return (
    <Stack>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <br />
          <br />
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            width={"95%"}
            m={"auto"}
          >
            <Grid item xs={5} alignContent="center">
              <Typography
                variant="h3"
                color="primary.dark"
                textAlign={{ xs: "center", md: "right" }}
              >
                Knowledge Contextualized
              </Typography>
              <Typography
                variant="h4"
                color="primary"
                fontWeight="bold"
                textAlign={{ xs: "center", md: "right" }}
              >
                learn by doing
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Card
                elevation={4}
                sx={{
                  p: 3,
                  backgroundColor: "primary.light",
                  borderRadius: "15px",
                  m: 5,

                  boxShadow: (theme) =>
                    `0em 0px 0.8em rgb(0 0 0 / 30%), 6px 6px ${theme.palette.primary.main}`,
                }}
              >
                <Typography
                  color="primary.dark"
                  variant="h5"
                  align="left"
                  m={1}
                >
                  User onboarding.
                </Typography>
                <Typography
                  color="primary.dark"
                  variant="h5"
                  align="left"
                  m={1}
                >
                  Continual learning.
                </Typography>
                <Typography
                  color="primary.dark"
                  variant="h5"
                  align="left"
                  m={1}
                >
                  Accessible reference material.
                </Typography>
                <Typography
                  color="primary.dark"
                  variant="h4"
                  fontWeight="bold"
                  align="left"
                >
                  All in the context of your browser.
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />

          <Box
            sx={{
              backgroundImage: (theme) =>
                `linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
              // backgroundColor: "primary.dark",
              width: "75%",
              margin: "auto",
              mb: -3,
              p: 1,
              borderRadius: 3,
            }}
          >
            <Typography
              color="white"
              margin="auto"
              variant="h5"
              p={4}
              // sx={{
              //   width: {
              //     xs: "95%",
              //     sm: "65%",
              //   },
              // }}
            >
              <Typography component="span" fontWeight="bold" variant="h5">
                NextStep
              </Typography>{" "}
              enables step-by-step training{" "}
              <Typography component="span" fontWeight="bold" variant="h5">
                directly within the web-based tools
              </Typography>{" "}
              that you and your organization use
            </Typography>
            {/* <Divider sx={{ borderColor: "primary.main" }} /> */}
            <br />
            {/* browser window */}
            <Paper
              elevation={20}
              // position={"relative"}
              // zIndex={1}
              sx={{
                display: "flex",
                // position: "relative",
                // zIndex: 1,
                flexDirection: "column",
                width: {
                  xs: "130%",
                  sm: "120%",
                  md: "110%",
                },
                aspectRatio: {
                  xs: 0.8,
                  md: 1.8,
                },
                ml: {
                  xs: "-15%",
                  sm: "-10%",
                  md: "-5%",
                },

                // margin: "auto",
                backgroundColor: "divider",
              }}
            >
              <Box
                sx={{
                  m: ".5%",
                  width: "99%",
                  height: "30px",
                  backgroundColor: "divider",
                }}
              >
                <Grid container>
                  <Grid item xs={3}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          m: 1,
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#EC6A5F",
                        }}
                      />
                      <Box
                        sx={{
                          my: 1,
                          mx: 0.5,
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#F4BF4F",
                        }}
                      />
                      <Box
                        sx={{
                          m: 1,
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#61C554",
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      align="left"
                      sx={{
                        pl: 0.5,
                        borderRadius: "10px",
                        color: "primary.dark",
                        backgroundColor: "lightgray",
                        m: 0.5,
                        width: "100%",
                        height: "24px",
                        overflow: "hidden",
                      }}
                    >
                      https://www.nextstepknowledge.com
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Box>
              <OverviewTabs />
            </Paper>

            <Stack
              // width="99%"
              // mt={1}
              // position={"relative"}
              // zIndex={0}
              // margin="auto"
              p={2}
              // sx={{
              //   backgroundColor: "primary.light",
              //   borderRadius: "0 0 5px 5px ",
              // }}
            >
              <br />
              <br />
              <Stack
                direction="row"
                // width="100%"
                // display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant={props.isMobile ? "h6" : "h4"}
                  color="primary.dark"
                >
                  {props.isMobile
                    ? "Use a desktop browser to see"
                    : "Expirence"}
                  <Typography
                    px={1}
                    component="span"
                    variant="inherit"
                    color="primary"
                    fontWeight="bold"
                  >
                    NextStep
                  </Typography>
                  in action
                </Typography>
              </Stack>

              {!props.isMobile && (
                <Stack direction="row">
                  {" "}
                  <NextStepButton
                    fullWidth
                    variant="contained"
                    size="large"
                    href={config.chromeExtensionURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Install on Chrome
                  </NextStepButton>
                  <NextStepButton
                    fullWidth
                    variant="contained"
                    size="large"
                    href={config.edgeExtensionUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Install on Edge
                  </NextStepButton>
                </Stack>
              )}
            </Stack>
          </Box>
          <br />

          <Container
            maxWidth={false}
            disableGutters={true}
            sx={{ my: 4, width: "100%" }}
          >
            <ImpactCards isMobile={props.isMobile} />
          </Container>
          <Container
            maxWidth={false}
            sx={{ py: 8, width: "100%", bgcolor: "primary.light" }}
          >
            <Typography color="primary.dark" variant="h4">
              <Typography
                px={1}
                component="span"
                variant="inherit"
                color="primary"
              >
                NextStep
              </Typography>
              works where you work
            </Typography>
            <br />
            <Card
              elevation={4}
              sx={{
                p: 5,
                backgroundColor: "white",
                borderRadius: "15px",
                my: 5,
                mx: {
                  xs: 4,
                  md: 10,
                },

                boxShadow: (theme) =>
                  `0em 0px 0.8em rgb(0 0 0 / 30%), 6px 6px ${theme.palette.primary.main}`,
              }}
            >
              <Typography color="primary.dark" variant="h6">
                Our browser-based solution is functionally aligned and
                organization-focused, as
                <Typography
                  px={1}
                  component="span"
                  variant="inherit"
                  color="primary"
                >
                  NextStep
                </Typography>
                can be deployed on the wide range of processes that are used by
                members of your organization.
              </Typography>
            </Card>
            <br />
            <br />
            <FeatureStepper isMobile={props.isMobile} />
            <NextStepButton
              variant="contained"
              size="large"
              onClick={() => {
                navigate("/about");
              }}
            >
              Learn more
            </NextStepButton>
          </Container>
          <Footer />
        </Box>
      </Box>
    </Stack>
  );
}
